/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/

@tailwind base;
@tailwind components;
@tailwind utilities;

.termsfeed-com---palette-light {
  max-width: 100% !important;
  width: 100% !important;
}

.termsfeed-com---palette-light .cc-nb-okagree {
  background-color: #006297 !important;
}

.termsfeed-com---palette-light .cc-nb-reject {
  background-color: #006297 !important;
}

.termsfeed-com---palette-light .cc-cp-foot-save {
  background: #006297 !important;
}

.termsfeed-com---pc-dialog
  input[type="checkbox"].cc-custom-checkbox:checked
  + label:before {
  background: #006297 !important;
}

.termsfeed-com---palette-light .cc-pc-head-lang select:focus {
  box-shadow: 0 0 0 2px #006297 !important;
}
